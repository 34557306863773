@charset "utf-8";

// Deprecation Warning: Using / for division outside of calc() is deprecated
// and will be removed in Dart Sass 2.0.0.
// Recommendation: math.div($header-height, 1.5) or calc($header-height / 1.5)
// More info and automated migrator: https://sass-lang.com/d/slash-div
@use 'sass:math';
@use 'sass:meta';

// Define defaults for each variable.

$base-font-family: Helvetica Neue, Helvetica, Arial, sans-serif, !default;
$base-font-size: 14px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.6 !default;
$base-transition-duration: 0.3s !default;

$spacing-unit:     30px !default;

$text-color:       #1d1d1f !default;
$background-color: #fff !default;

$grey-color:       #777 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$white-color: #fdfdfd !default;

$table-text-align: left !default;

$header-height: $base-line-height * $base-font-size * 2.85 !default;
$header-text-color: invert($theme-color) !default;
$header-background-color: $theme-color !default;

$footer-height: $header-height * 1.05 !default;
$footer-text-color: lighten(invert($theme-color), 25%) !default;
$footer-background-color: darken($theme-color, 5%) !default;

$banner-height: 640px !default;
$banner-text-color: lighten($white-color, 0%) !default;
$banner-background: rgba(0,0,0,0.8) !default;

// Width of the content area
// $content-width:    920px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: #{$spacing-unit / 2};
//     padding-left: #{$spacing-unit / 2};
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Better compatibility for divisions.
//
// Deprecation Warning: Using / for division outside of calc() is deprecated
// and will be removed in Dart Sass 2.0.0."
@function divide($dividend, $divisor: 1) {
  @if meta.function-exists('div', 'math') {
    @return math.div($dividend, $divisor);
  } @else {
    @return ($dividend / $divisor);
  }
}

// Import partials.
@import
  "yat/base",
  "yat/layout",
  "yat/dark",
  "misc/theme-toggle",
  "misc/article-menu",
  "misc/common-list",
  "misc/google-translate",
  "misc/gitment",
  "misc/click-to-top"
;
